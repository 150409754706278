import React from 'react';

const ProductModal = props => {
    const offers = props.product.offerData.offers.map((offer, index) => {
            return(
                <tr key={index}>
                    <td>{offer.seller.displayName}</td>
                    <td>{offer.seller.topSeller}</td>
                    <td>€ {offer.price}</td>
                    <td><i className="fas fa-eye"></i></td>
                </tr>
            );
    });

    return(
        <div className="product-modal">
            <div className="product-modal-info">
                <div className="container-fluid">
            	    <div className="row">
                        <a href="_blank" className="close" onClick={props.close}><i className="fas fa-times"></i></a>
                        <div className="product-title">
                            <h4 className="h4">{props.product.title}</h4>
                        </div>   
                        <h5>Offers</h5>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Verkoper</th>
                                    <th>Topverkoper</th>
                                    <th>Prijs</th>
                                    <th>Track</th>
                                </tr>
                            </thead>
                            <tbody>
                                {offers}
                            </tbody>
                        </table> 
                    </div>
                </div>
            </div>
            <div className="product-modal-bg" onClick={props.close}></div>  
        </div>
    );
    
}

export default ProductModal;