import React, { Component } from 'react';
import axios from 'axios';

class UpgradeModal extends Component {
    constructor(){
        super();

        this.state = {
            plans: [],
            newSub: ""
        }
    }

    componentDidMount = () => {
        document.getElementById("outerModal").addEventListener("click", this.modalFunction);
        document.getElementById("close").addEventListener("click", this.modalFunction);

        axios.get("https://api.bolscout.nl/api/plan")
        .then(response => {
            this.setState({
                plans: response.data
            });
        });
    }

    componentWillUnmount = () => {
        document.getElementById("outerModal").addEventListener("click", this.modalFunction);
        document.getElementById("close").addEventListener("click", this.modalFunction);
    }

    modalFunction = (event) => {
        event.preventDefault();

        this.props.setModal(false);
    }

    upgradeSubscription = () => {  
        if (this.state.newSub === "-- Kies een abonnement --" || this.state.newSub === "") {
            return false;
        }

        console.log(this.props.subscription)

        var token = document.cookie.match('(^|;) ?token=([^;]*)(;|$)');
        if(token) {
            var params = new URLSearchParams();
            params.append("subscription", this.props.subscription.subscription);
            params.append("newPlan", this.state.newSub);
            params.append("session_token", token[2]);

            axios({
                method: "post",
                url: "https://api.bolscout.nl/api/invoice/upgrade",
                data: params
            })
            .then(response => {
                if(response.data.error) {
                    alert(response.data.error);
                } else {
                    alert(response.data.message);
                    window.location.reload();
                }
            });
        }
    }

    setNewSubscription = (event) => {
        this.setState({
            newSub: event.target.options[event.target.selectedIndex].value
        });
    }

    render() {
        let periodMessage = "";
        if(this.props.subscription.period === "year") {
            periodMessage = "U heeft een jaarlijks abonnement, er is geen upgrade beschikbaar voor u.";
        } else if(this.props.subscription.period === "quarter") {
            periodMessage = "U heeft op dit moment een kwartaal abonnement, uw abonnement loopt na dit kwartaal af en is dan maandelijks opzegbaar. u kunt ook upgrade naar het jaarlijkse abonnement met een goedkopere maandprijs?";
        } else if(this.props.subscription.period === "month") {
            periodMessage = "U heeft op dit moment een per maand opzegbaar abonnement bij ons, wilt u upgraden naar een langer abonnement met een goedkopere maandprijs?";
        }

        let options = this.state.plans.map((plan, index) => {
            if(plan.plan_period === this.props.subscription.period) {
                return false;
            }
            if(this.props.subscription.period === "quarter" && (plan.plan_period  === "month" || plan.plan_period === this.props.subscription.period)) {
                return false;
            }
            if(this.props.subscription.period === "year" && (plan.plan_period  === "month" || plan.plan_period === "quarter")) {
                return false;
            }
            return <option value={plan.plan_id} key={index}>{plan.plan_name} - €{(plan.plan_price / 100).toFixed(2)} per maand</option>
        });

        return(
            <div className="trackModal">
                <div id="outerModal" className="outerModal"></div>
                <div className="innerModal-big bg-light" id="innerModal">
                    <div className="modal-head">
                    <h4 className="modal-title">Abonnement upgrade</h4>
                        <a href="/" className="modal-close" id="close"><i className="fas fa-times-circle"></i></a>
                    </div>
                    <div>
                       <p>{periodMessage}</p>
                       <select onChange={this.setNewSubscription}>
                            <option>-- Kies een abonnement --</option>
                            {options}   
                        </select>
                        <button onClick={this.upgradeSubscription}>Upgrade!</button>
                        <p><b>Als u uw abonnement op annuleren heeft staan, wordt de annulatie gestopt en gaat het abonnement gewoon door tot de nieuwe einddatum!</b></p>
                        {/* <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Abonnement</th>
                                    <th>Nieuw abonnement</th>
                                </tr>
                            </thead>
                            <tbody>

                            </tbody>
                        </table> */}
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default UpgradeModal;