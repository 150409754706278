import React, { Component } from 'react';
import { IbanElement, injectStripe } from 'react-stripe-elements';
import axios from 'axios';

class Form extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);

        this.state = {
            errorMessage: "",
            accountCreated: false,
            plans: []
        }
    }

    componentWillMount() {
        // Gets the payment plans from bolscout server through api
        axios.get("https://api.bolscout.nl/api/plan")
        .then(response => {
            this.setState({
                plans: response.data
            });
        });
    }     

    componentDidMount() {
        document.getElementById("subscription-form").addEventListener("submit", this.submit);
    }

    handleChange = ({error}) => {
        // Used to show the iban error message
        if (error) {
          this.setState({errorMessage: error.message});
        }
    }
    
    customerCheck = () => {
        var subscriptionForm = document.getElementById("subscription-form");

        const greenBorder = "1px solid green";
        const redBorder = "1px solid red";

        var error = 0;

        var firstName = subscriptionForm.querySelector('[name=firstName]');
        var firstNameMsg = subscriptionForm.querySelector("#firstname-message");
        if(firstName.value !== "") {
            firstName.style.border = greenBorder;
            firstNameMsg.innerHTML = "";
        } else {
            firstName.style.border = redBorder;
            firstNameMsg.innerHTML = "Voornaam niet ingevuld.";

            error++;
        }

        var lastName = subscriptionForm.querySelector('[name=lastName]');
        var lastNameMsg = subscriptionForm.querySelector("#lastname-message");
        if(lastName.value !== "") {
            lastName.style.border = greenBorder;
            lastNameMsg.innerHTML = "";
        } else {
            lastName.style.border = redBorder;
            lastNameMsg.innerHTML = "Achternaam niet ingevuld.";

            error++;
        }

        var email = subscriptionForm.querySelector('[name=email]');
        var emailMsg = subscriptionForm.querySelector("#email-message");
        if(email.value !== "") {
            email.style.border = greenBorder;
            emailMsg.innerHTML = "";
        } else {
            email.style.border = redBorder;
            emailMsg.innerHTML = "Email niet ingevoerd!";

            error++;
        }

        var dateOfBirth = subscriptionForm.querySelector('[name="birthDate"]');
        var dateOfBirthMsg = subscriptionForm.querySelector("#birthdate-message");
        if(dateOfBirth.value !== "") {
            
            let today = new Date();
            let birthDate = new Date(dateOfBirth.value);
            let age = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }    

            if (age < 18) {
                dateOfBirth.style.border = redBorder;
                dateOfBirthMsg.innerHTML = "Verplicht boven de 18 te zijn!";

                error++;
            } else {
                dateOfBirth.style.border = greenBorder;
            }
        } else {
            dateOfBirth.style.border = redBorder;
            dateOfBirthMsg.innerHTML = "Geen geboortedatum gekozen.";
            
            error++
        }

        var password = subscriptionForm.querySelector('[name="password"]');
        var passwordMsg = subscriptionForm.querySelector("#password-message");
        var confirmPassword = subscriptionForm.querySelector('[name="confirmPassword"]');
        if(password.value !== "") {
            if (password.value === confirmPassword.value) {
                password.style.border = greenBorder;
                confirmPassword.style.border = greenBorder;
                passwordMsg.innerHTML = "";
            } else {
                password.style.border = redBorder;
                confirmPassword.style.border = redBorder;
                passwordMsg.innerHTML = "Wachtwoorden komen niet overeen!";
                error++;
            }
        } else {
            password.style.border = redBorder;
            confirmPassword.style.border = redBorder;
            passwordMsg.innerHTML = "passwords niet ingevuld!";
            error++;
        }

        var address = subscriptionForm.querySelector('[name="address"]');
        var addressMsg = subscriptionForm.querySelector("#address-message");
        if(address.value !== "") {
            address.style.border = greenBorder;
            addressMsg.innerHTML = "";
        } else {
            address.style.border = redBorder;
            addressMsg.innerHTML = "veld niet ingevuld!";
            error++;
        }

        var city = subscriptionForm.querySelector('[name="city"]');
        var cityMsg = subscriptionForm.querySelector("#city-message");
        if(city.value !== "") {
            city.style.border = greenBorder;
            cityMsg.innerHTML = "";
        } else {
            city.style.border = redBorder;
            cityMsg.innerHTML = "veld niet ingevuld!";
            error++;
        }

        var zipcode = subscriptionForm.querySelector('[name="zipcode"]');
        var zipcodeMsg = subscriptionForm.querySelector("#zipcode-message");
        if(zipcode.value !== "") {
            zipcode.style.border = greenBorder;
            zipcodeMsg.innerHTML = "";
        } else {
            zipcode.style.border = redBorder;
            zipcodeMsg.innerHTML = "veld niet ingevuld!";
            error++;
        }

        var province = subscriptionForm.querySelector('[name="province"]');
        var provinceMsg = subscriptionForm.querySelector("#province-message");
        if(province.value !== "") {
            province.style.border = greenBorder;
            provinceMsg.innerHTML = "";
        } else {
            province.style.border = redBorder;
            provinceMsg.innerHTML = "veld niet ingevuld!";
            error++;
        }
        
        var country = subscriptionForm.querySelector('[name="country"]');
        var countryMsg = subscriptionForm.querySelector("#country-message");
        if(country.value !== "") {
            country.style.border = greenBorder;
            countryMsg.innerHTML = "";
        } else {
            country.style.border = redBorder;
            countryMsg.innerHTML = "veld niet ingevuld!";
            error++;
        }

        if(error > 0) {
            return false;
        } else {
            return true;
        }

    }

    createCustomer = () => {
        let subscriptionForm = document.getElementById("subscription-form");

        // Creates the customer object
        // Stores data in the object
        const customer = {
            firstName: subscriptionForm.querySelector('[name="firstName"]').value,
            lastName: subscriptionForm.querySelector('[name="lastName"]').value,
            email: subscriptionForm.querySelector('[name="email"]').value,
            birthDate: subscriptionForm.querySelector('[name="birthDate"]').value,
            password: subscriptionForm.querySelector('[name="password"]').value,
            confirmPassword: subscriptionForm.querySelector('[name="confirmPassword"]').value,
            company: subscriptionForm.querySelector('[name="company"]').value,
            address: subscriptionForm.querySelector('[name="address"]').value,
            city: subscriptionForm.querySelector('[name="city"]').value,
            zipcode: subscriptionForm.querySelector('[name="zipcode"]').value,
            province: subscriptionForm.querySelector('[name="province"]').value,
            country: subscriptionForm.querySelector('[name="country"]').value,
        }

        // Stores the payment plan in the customer object
        let paymentPlans = document.getElementsByName("paymentPlan");
        for(let paymentPlan of paymentPlans) {
            if(paymentPlan.checked === true) {
                customer["plan"] = this.state.plans[paymentPlan.value].plan_id;
            }
        }

        // Returns the customer object.
        return customer;        
    }

    addPostParams = (customer, paymentMethod) => {
        // Creates a new instance of URLSearchParams()
        let params = new URLSearchParams();

        // Loads the customer object into a loop and make post parameters
        for(let data in customer) {
            params.append(data, customer[data]);
        }

        // Creates a post parameter of the payment method
        params.append("stripeToken", paymentMethod);

        // Return the parameters to the submit function
        return params;
    }

    buttonNoClick = (event) => {
        event.preventDefault();
    }

    async submit(event) {
        // Prevents the default submit action
        event.preventDefault();        

        // // Creates the customer object;
        let customer = this.createCustomer();

        // // Checks if data has been filled in
        if(!this.customerCheck()) {
            return false;
        }

        // // Change button to unclickable.
        document.getElementById("subscription-form").removeEventListener("submit", this.submit);
        document.getElementById("subscription-form").addEventListener("submit", this.buttonNoClick);
        var button = event.target.querySelector("#register-button");
        button.classList.add("disabled");
        button.innerHTML = "Loading...";

        this.props.stripe.createToken({
            currency: 'eur',
            account_holder_name: customer.firstName + " " + customer.lastName,
            account_holder_type: 'individual',
        })
        .then(result => {
            let displayError = document.getElementById('error-message');

            if(result.error) {
                document.getElementById("subscription-form").removeEventListener("submit", this.buttonNoClick);
                document.getElementById("subscription-form").addEventListener("submit", this.submit);
                var button = event.target.querySelector("#register-button");
                button.classList.remove("disabled");
                button.innerHTML = "Registreren";
                displayError.textContent = result.error.message;
                return false;
            } else {
                // If there is no error the script wil continue
                // Variable params, will create the post parameters.
                let params = this.addPostParams(customer, result.token.id);

                // With axios we post the data to the bolscout api
                axios({
                    method: "post",
                    url: "https://api.bolscout.nl/api/payment",
                    data: params
                })
                .then(response => {
                    if(response) {
                        // If there is a response it will show that the account has been created.
                        alert('Account is aangemaakt, u kunt nu inloggen!');
                        // After the message it will redirect you to the login screen.
                        window.location.replace("/");
                    }
                }).catch(error => {
                    // If there is an error there will be a message that you can't create an account.
                    if(error) {
                        alert("Op dit moment kunt u geen account aanmaken, probeer het later opnieuw!");
                    }
                });
            }
        });
        
    }

    render() {
        const plans = this.state.plans.map((plan, index) => {
            return (
                <div className="form-check mt-4" key={index}>
                    <input type="radio" name="paymentPlan" id={"plan" + index} value={index} defaultChecked={index === 0 ? true : false} /> &nbsp;
                    <label htmlFor={"plan" + index}>{plan.plan_name} (€ {plan.plan_price / 100})</label><br />
                    <p style={{maxWidth: "900px"}}><b>{plan.plan_description}</b></p>
                </div>   
            );
        });

        return (
            <form id="subscription-form">

                <div className="row">
                    <div className="col-md-12">
                        <h3 className="h3">Persoonlijke gegevens</h3>
                        <span><b>* is verplicht</b></span>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="firstName">Voornaam *</label>
                            <input type="text" className="form-control" name="firstName" id="firstName" aria-describedby="First name" placeholder="Voornaam" />
                            <span id="firstname-message" className="form-error-message"></span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="lastName">Achternaam *</label>
                            <input type="text" className="form-control" name="lastName" id="lastName" aria-describedby="Last name" placeholder="Achternaam" />
                            <span id="lastname-message" className="form-error-message"></span>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="email">Email *</label>
                            <input type="email" className="form-control" name="email" id="email" aria-describedby="Email" placeholder="Email" />
                            <span id="email-message" className="form-error-message"></span>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="birthDate">Geboortedatum *</label>
                            <input type="date" className="form-control" name="birthDate" id="birthDate" aria-describedby="Geboortedatum" placeholder="Geboortedatum" />
                            <span id="birthdate-message" className="form-error-message"></span>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <h3 className="h3">Wachtwoord</h3>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="password">Wachtwoord *</label>
                            <input type="password" className="form-control" name="password" id="password" aria-describedby="Wachtwoord" placeholder="Wachtwoord" />
                            <span id="password-message" className="form-error-message"></span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="confirmPassword">Bevestig wachtwoord *</label>
                            <input type="password" className="form-control" name="confirmPassword" id="confirmPassword" aria-describedby="Bevestig wachtwoord" placeholder="Bevestig wachtwoord" />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <h3 className="h3">Bedrijfsgegevens</h3>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="company">Bedrijfsnaam</label>
                            <input type="text" className="form-control" name="company" id="company" aria-describedby="Bedrijfsnaam" placeholder="Bedrijfsnaam" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="address">Straatnaam + huisnummer *</label>
                            <input type="text" className="form-control" name="address" id="address" aria-describedby="Straatnaam + huisnummer" placeholder="Straatnaam + huisnummer" />
                            <span id="address-message" className="form-error-message"></span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="city">Woonplaats *</label>
                            <input type="text" className="form-control" name="city" id="city" aria-describedby="Woonplaats" placeholder="Woonplaats" />
                            <span id="city-message" className="form-error-message"></span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="zipcode">Postcode *</label>
                            <input type="text" className="form-control" name="zipcode" id="zipcode" aria-describedby="Postcode" placeholder="Postcode" />
                            <span id="zipcode-message" className="form-error-message"></span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="province">Provincie *</label>
                            <input type="text" className="form-control" name="province" id="province" aria-describedby="Provincie" placeholder="Provincie" />
                            <span id="province-message" className="form-error-message"></span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="country">Land *</label>
                            <input type="text" className="form-control" name="country" id="country" aria-describedby="Land" placeholder="Land" />
                            <span id="country-message" className="form-error-message"></span>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <h3 className="h3">Pakketkeuze *</h3>
                    </div>
                    <div className="col-md-12">
                        {plans}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="h3">Betaling</h3>
                        <span>Het maandelijkse bedrag wordt per maand afgeschreven van de gegeven rekenening.</span>
                    </div>
                    <div className="col-md-12">
                        <div className="checkout">
                            <label>Iban *</label>
                            <IbanElement onChange={this.handleChange} supportedCountries={['SEPA']} />
                            <div id="error-message" role="alert">{this.state.errorMessage}</div>
                        </div>
                    </div>
                    <div className="col-md-12 text-justify pb-2 pt-2 mt-3 mb-3 card">
                        <div id="mandate-acceptance">
                            By providing your IBAN and confirming this payment, you are authorizing
                            Bolscout.nl and Stripe, our payment service provider, to send
                            instructions to your bank to debit your account in accordance with those
                            instructions. You are entitled to a refund from your bank under the terms
                            and conditions of your agreement with your bank. A refund must be claimed
                            within 8 weeks starting from the date on which your account was debited.
                        </div>
                    </div>
                </div>
                        
                <button type="submit" id="register-button" className="btn btn-primary w-100">Registreren</button>

            </form>
        );
    }
}

export default injectStripe(Form);