import React from 'react';
import TableRow from './TableRow';

const ProductTable = props => {
    const tableRow = props.products.map((item, index) => {
        return <TableRow key={index} product={item} infoToggler={props.infoToggler} trackProduct={props.trackProduct} tracks={props.tracks} max={props.max} />;                    
    });

    return(
        <table className="table" id="table">
            <thead>
                <tr>
                    <th>Product</th>
                    <th>Rating</th>
                    <th colSpan="4">Prijs</th>
                </tr>
            </thead>
            <tbody>
                {tableRow}
            </tbody>
        </table>
    );
}

export default ProductTable;