import React from 'react';

const checkForImage = (product) => {
    if(Object.keys(product).includes('images')) {
        if(Object.keys(product.images).includes('4')) {
            if(Object.keys(product.images[4]).includes('url')) {
                return <img src={Object.values(product.images)[4]["url"]} alt={product.title} className="figure-img product-thumbnail" />;
            }
        }
    }
}

const message = () => {
    alert("U tracked al het maximaal aantal producten");
}

const TableRow = props => {
    const checkImage = checkForImage(props.product);

    return(
        <tr>
            <td>
                <div className="float-left mr-3">
                    <figure className="figure">
                        {checkImage}
                    </figure>
                </div>
                <div className="float-left">
                    <p>{props.product.title}</p>
                    <small>EAN: {props.product.ean}</small>
                </div>
            </td>
            <td>{props.product.rating / 10} / 5</td>
            <td>€{Object.values(props.product.offerData.offers)[0]["price"]}</td>
            {/* <td><button className="btn btn-info" onClick={() => {props.infoToggler(props.product)}}><i className="fas fa-info-circle"></i></button></td> */}
            <td><button className="btn btn-info" onClick={props.tracks < props.max ? () => {props.trackProduct(props.product)} : () => {message()}}><i className="fas fa-eye"></i></button></td>
            <td><a href={Object.values(props.product.urls)[0]["value"]} rel="noopener noreferrer" target="_blank" className="btn btn-info" ><i className="fas fa-external-link-alt"></i></a></td>
        </tr>
    );
}

export default TableRow;