import React, { Component } from 'react';
import axios from 'axios';

class Invoices extends Component {
    constructor(props) {
        super(props);

        this.state = {
            invoices: [],
            message: ""
        }
    }

    componentWillMount() {
        setTimeout(this.setInvoices, 150);
    }

    setInvoices = () => {
        var token = document.cookie.match('(^|;) ?token=([^;]*)(;|$)');
        if(token) {
            if(this.props.customer !== "") {
                var params = new URLSearchParams();
                params.append("session_token", token[2]);
                params.append("stripe_customer", this.props.customer);

                
                axios({
                    method: "post",
                    url: "https://api.bolscout.nl/api/invoice",
                    data: params
                })
                .then(response => {
                    if(response.data.invoices.length > 0) {
                        this.setState({
                            invoices: response.data.invoices
                        });
                    } else {
                        this.setState({
                            message: response.data.message
                        });
                    }
                });
            } else {
                this.setState({
                    message: "Geen facturen opgehaald"
                });
            }
        }
    }

    render() {
        const getInvoices = this.state.invoices.map((invoice, index) => {
            let date = new Date(invoice.invoice_date * 1000);
            return(
                <tr key={index}>
                    <td>{invoice.invoice_number}</td>
                    <td>{date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear()}</td>
                    <td>€{(invoice.invoice_amount / 100).toFixed(2).replace('.', ',')}</td>
                    <td><a href={invoice.invoice_pdf} className="btn btn-info">Factuur</a></td>
                </tr>
            );

            
        });

        return (
            <div className="row pt-4">
                <div className="col-md-12">
                    <h3>Facturen</h3>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Factuurnummer</th>
                                <th>Datum</th>
                                <th>Prijs</th>
                                <th>Bekijk</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.invoices.length > 0 ? getInvoices : (<tr><td>{this.state.message}</td></tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default Invoices;