import React, { Component } from 'react';
import axios from 'axios';
import BookmarkNav from './BookmarkNav';
import TrackModal from './TrackModal';

class ProductTracker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bookmarks: [],
            data: [],
            trackModal: false,
            modalData: [],
            message: "",
            max: 120,
            tracks: 0
        }

    }

    unmounted = false;

    componentWillMount = () => {
        setTimeout(() => {
            this.getBookmarks();
        }, 100);
        
        var token = document.cookie.match('(^|;) ?token=([^;]*)(;|$)');
        if(token) {

            var params = new URLSearchParams();
            params.append('session_token', token[2]);
            axios.post("https://api.bolscout.nl/api/tracking/count", params)
            .then(response => {
                if(response.data.error) {
                    
                } else {
                    this.setState({
                        tracks: response.data.total
                    });
                }
            });
        }
    }

    getBookmarks = () => {
        var token = document.cookie.match('(^|;) ?token=([^;]*)(;|$)');
        if(token) {

            var params = new URLSearchParams();
            params.append('session_token', token[2]);

            axios({
                method: 'post',
                url: 'https://api.bolscout.nl/api/bookmark',
                data: params
            })
            .then(bookmarks => {
                if(bookmarks.data.bookmarks.length > 0) {
                    this.setState({
                        bookmarks: bookmarks.data.bookmarks,
                        message: ""
                    }, () => {
                        this.getTracks(this.state.bookmarks[0].bookmark_id);
                        document.getElementById("bookmark-" + this.state.bookmarks[0].bookmark_id).parentNode.classList.add("active");
                    });
                } else {
                    this.setState({
                        bookmarks: [],
                        message: "U heeft nog geen bookmarks aangemaakt."
                    })
                }
            }).catch(error => {
                this.setState({
                    message: "Bookmarks kunnen niet opgehaald worden"
                })
            })
        }
    }

    addBookmark = (event) => {
        event.preventDefault();

        var token = document.cookie.match('(^|;) ?token=([^;]*)(;|$)');
        if(token) {
            var bookmarkName = prompt("Voer de bookmark naam in:");
            if(bookmarkName) {
                const params = new URLSearchParams();
                params.append("session_token", token[2]);
                params.append("bookmark", bookmarkName);

                axios({
                    method: "post",
                    url: "https://api.bolscout.nl/api/bookmark/insert",
                    data: params
                }).then(response => {
                    this.getBookmarks();
                    alert(response.data.message);
                });
            } else if(bookmarkName === "") {
                alert("Je hebt geen naam ingevoerd!");
            }
        }
    }

    removeBookmark = event => {
        event.preventDefault();

        var token = document.cookie.match('(^|;) ?token=([^;]*)(;|$)');
        if(token) { 
            if(event.target.id !== "") {
                if(window.confirm("Weet u zeker dat u deze bookmark wilt verwijderen?")) {
                    var message = "";
                    const params = new URLSearchParams();
                    params.append("session_token", token[2])
                    params.append("bookmarkID", event.target.id);
                    axios({
                        method: "post",
                        url: "https://api.bolscout.nl/api/bookmark/delete/",
                        data: params
                    })
                    .then(response => {
                        this.getBookmarks();
                        message = response.data.message;
                    }).catch(response => {
                        message = "Bookmark kon niet verwijderd worden, probeer het later opnieuw";
                    }).finally(() => {
                        alert(message);
                    });
                }
            }
        }
    }

    getTracks = bookmarkID => {
        var params = new URLSearchParams();
        params.append("bookmarkID", bookmarkID)

        axios({
            method: "post",
            url: "https://api.bolscout.nl/api/tracking/get",
            data: params
        })
        .then(response => {
            if(response.data.length > 0) {
                this.setState({
                    data: response.data,
                    bookmark: bookmarkID,
                    message: ""
                });
            } else {
                this.setState({
                    message: "Nog geen producten getracked."
                })
            }
        });
    }

    loadBookmark = event => {
        event.preventDefault();
        
        var parentLi = event.target.parentNode
        var parentUl = parentLi.parentNode;
        for(var li of parentUl.getElementsByTagName("li")) {
            li.classList.remove("active");
        }
        parentLi.classList.add("active");

        var bookmarkID = event.target.id;
        bookmarkID = bookmarkID.split("-");
        this.getTracks(bookmarkID[1]);
    }

    checkForImage = (product) => {
        if(Object.keys(product).includes('track_images')) {
            if(Object.keys(product.track_images).includes('4')) {
                if(Object.keys(product.track_images[4]).includes('url')) {
                    return <img src={Object.values(product.track_images)[4]["url"]} alt={product.track_title} className="figure-img product-thumbnail" />;
                }
            }
        }
    }

    deleteTrack = (product) => {
        let bookmarkID = this.state.bookmark;
        let productID = product.track_id;

        if(window.confirm("Weet u zeker dat u het product wilt verwijderen?")) {
            var token = document.cookie.match('(^|;) ?token=([^;]*)(;|$)');
            if(token) {

                let params = new URLSearchParams();
                params.append('bookmarkID', bookmarkID);
                params.append('productID', productID);
                params.append('session_token', token[2]);

                axios.post("https://api.bolscout.nl/api/tracking/delete", params)
                .then((response) => {
                    if(response.data) {
                        this.getBookmarks();
                    }
                })
            }
        }
    }

    trackModal = (setModal, product = []) => {
        this.setState({
            trackModal: setModal,
            modalData: product
        });
    }

    render() {
        const kl = this.state.data.map((data, index) => {
            return(
                <tr key={index}>
                    <td>
                        <div className="float-left mr-3">
                            <figure className="figure">
                                {this.checkForImage(data)}
                            </figure>
                        </div>
                        <div className="float-left">
                            <p>{data.track_title}</p>
                            <small>EAN: {data.track_ean}</small>
                        </div>
                    </td>
                    <td>
                        <button className="btn btn-info" onClick={() => {this.trackModal(true, data)}}>Check <i className="far fa-eye"></i></button>
                    </td>
                    <td>
                        <button className="btn btn-info" onClick={() => {this.deleteTrack(data)}}>Verwijder <i className="far fa-times-circle"></i></button>
                    </td>
                </tr>
                   
            );
        });

        var dataExists;
        if(this.state.data.length > 0) {
            dataExists = (
                <table className="table" id="table">
                    <thead>
                        <tr>
                            <th colSpan="3">Product</th>
                        </tr>
                    </thead>
                    <tbody>
                        {kl}
                    </tbody>
                </table>  
            );
        } else {
            dataExists = (
                <h3>{this.state.message}</h3>
            );
        }

        return(
            <div>
                {this.state.trackModal && <TrackModal setModal={this.trackModal} product={this.state.modalData} />}
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap pt-3 pb-2 mb-3 border-bottom align-items-center">
                    <h1 className="h2">Tracker</h1>
                    <div className="float-right">
                        <p>Tracked products: {this.state.tracks} / {this.state.max}</p>
                    </div>
                </div>
                <div className="card my-4">
                    <BookmarkNav load={this.loadBookmark} add={this.addBookmark} remove={this.removeBookmark} bookmarks={this.state.bookmarks} />
                    <div className="card-body">
                        {this.state.message === "" ? dataExists : <h3>{this.state.message}</h3>}
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductTracker;