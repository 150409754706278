import React, { Component } from 'react';
import axios from 'axios';

class PasswordSettings extends Component {
    constructor(){
        super();

        this.state = {
            previousPass: "",
            newPassword: "",
            confirmPass: ""
        }
    }

    componentWillMount() {
        document.title = "Instellingen - Wachtwoord instellingen";
    }

    updatePassword = event => {
        event.preventDefault();

        if(!this.formCheck()) {
            return false;
        }

        if((this.state.previousPass !== "") || (this.state.newPassword !== "") || (this.state.confirmPass !== "")) {  
            var token = document.cookie.match('(^|;) ?token=([^;]*)(;|$)');
            if(token) {
                
                let params = new URLSearchParams();
                params.append("session_token", token[2]);
                params.append("userData", JSON.stringify(this.state));

                axios({
                    method: "post",
                    url: "https://api.bolscout.nl/api/user/password",
                    data: params
                })
                .then(response => {
                    if(response.data.state) {
                        alert(response.data.message);
                    } else {
                        document.getElementById("previousPass").style.border = "red";
                        document.getElementById("newPassword").style.border = "red";
                        document.getElementById("confirmPass").style.border = "red";
                        document.getElementById("newPasswordMsg").innerHTML = response.data.message;
                    }
                }).catch(error => {

                });
            
            }

        }
    }

    formCheck = () => {
        const greenBorder = "1px solid green";
        const redBorder = "1px solid red";
        
        var error = 0;

        var previousPass = document.getElementById("previousPass");
        if((this.state.firstname !== "") && (previousPass.value !== "")) {
            previousPass.style.border = greenBorder;
        } else {
            previousPass.style.border = redBorder;

            error++;
        }

        var newPassword = document.getElementById("newPassword");
        var newPasswordMsg = document.getElementById("newPasswordMsg");
        var confirmPassword = document.getElementById("confirmPass");
        if(newPassword.value !== "") {
            if (newPassword.value === confirmPassword.value) {
                newPassword.style.border = greenBorder;
                confirmPassword.style.border = greenBorder;
                newPasswordMsg.innerHTML = "";
            } else {
                newPassword.style.border = redBorder;
                confirmPassword.style.border = redBorder;
                newPasswordMsg.innerHTML = "Wachtwoorden komen niet overeen!";
                error++;
            }
        } else {
            newPassword.style.border = redBorder;
            confirmPassword.style.border = redBorder;
            newPasswordMsg.innerHTML = "Wachtwoord niet ingevuld!";
            error++;
        }

        if(error > 0) {
            return false;
        } else {
            return true;
        }
    }

    dataHandler = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    render() {
        return(
            <div className="col-md-12 pt-2 pb-4 bg-white">
                <h3 className="h3">Wachtwoord wijzigen</h3> 
                <form onSubmit={this.updatePassword}>
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div className="form-group">
                                <label htmlFor="previousPass">Oud wachtwoord</label>
                                <input type="password" className="form-control" name="previousPass" id="previousPass" aria-describedby="Oud wachtwoord" onChange={this.dataHandler} placeholder="Oud wachtwoord" />
                                <span id="passwordMsg" className="form-error-message"></span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-6">
                            <div className="form-group">
                                <label htmlFor="newPassword">Nieuw wachtwoord</label>
                                <input type="password" className="form-control" name="newPassword" id="newPassword" aria-describedby="Nieuw wachtwoord" onChange={this.dataHandler} placeholder="Nieuw wachtwoord" />
                                <span id="newPasswordMsg" className="form-error-message"></span>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6">
                            <div className="form-group">
                                <label htmlFor="confirmPass">Bevestig Wachtwoord</label>
                                <input type="password" className="form-control" name="confirmPass" id="confirmPass" aria-describedby="Bevestig wachtwoord" onChange={this.dataHandler} placeholder="Bevestig wachtwoord"  />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-4">
                            <button className="btn btn-outline-dark w-100">Opslaan</button>
                        </div>
                    </div>  
                </form>
            </div>
        );
    }
}

export default PasswordSettings;