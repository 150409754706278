import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Elements, StripeProvider } from 'react-stripe-elements';
import Form from './Form';

class Signup extends Component {
    constructor() {
        super();

        this.state = {

        }
    }

    componentDidMount() {
        
    }

    signupHandler = event => {
        event.preventDefault();
        console.log("signedup");
    }

    render() {
        return(
            <div className="container-fluid bg-dark">
                <div className="row h-100vh flex-column flex-login">
                    <div className="signup-block bg-light">
                        <h2 className="h2">Registreren</h2>
                        <Link to="/" className="link">Heeft u al een account? log in!</Link>
                        {/* <StripeProvider apiKey="pk_test_Jltbgjebqw56e74DpbJ6MP3W00pZr9ole7"> */}
                        <StripeProvider apiKey="pk_live_NBN4aNxtQBrHhuF9XwxM9MGC00ekMwENht">
                            <Elements>
                                <Form />
                            </Elements>
                        </StripeProvider>
                    </div>
                </div>
            </div>
        );
    }
}

export default Signup;