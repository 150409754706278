import React, { Component } from 'react';
import axios from 'axios';
import ProductTable from './ProductTable';
import ProductModal from './ProductModal';
import TrackModal from './TrackModal';
import Sort from '../common/form/Select';
import './ProductDatabase.scss';

class ProductDatabase extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = { 
            data: [],
            categories: [],
            subCategories: [],
            selectedCategory: "",
            limit: 25,
            offset: 0,
            query: "",
            sort: "rankasc",
            order: [
                {method: 'rankasc', name: 'Sort on sales ranking ascending'},
                {method: 'rankdesc', name: 'Sort on sales ranking descending'},
                {method: 'priceasc', name: 'Sort on price ascending'},
                {method: 'pricedesc', name: 'Sort on price descending'},
                {method: 'titleasc', name: 'Sort on title ascending'},
                {method: 'titledesc', name: 'Sort on title descending'},
                {method: 'dateasc', name: 'Sort on publishing date ascending'},
                {method: 'datedesc', name: 'Sort on publishing date descending'},
                {method: 'ratingasc', name: 'Sort on rating ascending'},
                {method: 'ratingdesc', name: 'Sort on rating descending'},
            ],
            isLoading: false,
            trackVisible: false,
            singleProduct: [],
            showModal: false,
            ModalData: '',
            bookmarks: [] ,
            max: 120,
            tracks: 0
        };

    }

    componentDidMount = () => {
        this.getProducts();
        this.getBookmarks();

        var token = document.cookie.match('(^|;) ?token=([^;]*)(;|$)');
        if(token) {

            var params = new URLSearchParams();
            params.append('session_token', token[2]);
            axios.post("https://api.bolscout.nl/api/tracking/count", params)
            .then(response => {
                if(response.data.error) {
                    
                } else {
                    this.setState({
                        tracks: response.data.total
                    });
                }
            });
        }
    }

    setSort = input => {
        if(this._isMounted) return;
        this.setState({
            sort: input.target.value,
            offset: 0
        });

        if(this.state.query === "") {
            this.getProducts(input.target.value);
        } else {
            this.searchProducts(input.target.value);
        }
    }

    getProducts = (sort = "rankasc") => {
        axios.get(`https://api.playerbuilds.com/api/product?ids=${this.state.selectedCategory}&limit=${this.state.limit}&sort=${sort}`)
        .then(products => {
            if(this._isMounted) return;
            this.setState({
                categories: [],
                data: products.data.products,
            });
        })
        .catch(error => {
            console.log(error);
        });  
    }

    searchProducts = (sort = "rankasc") => {
        var query = document.getElementById("query").value;
        this.setState({
            data: [],
            query: query,
            offset: 0
        });
       
        if(query !== "") {
            axios.get(`https://api.playerbuilds.com/api/product/search?ids=${this.state.selectedCategory}&product=${query}&limit=${this.state.limit}&sort=${sort}`)
            .then(products => {
                if(!Object.values(products)[0]['error']) {
                    this.setState({
                        data: products.data.products,
                        loaded: true,
                    }); 
                } else {
                    console.log(Object.values(products)[0]['error']);
                }
            })
            .catch(error => {
                console.log(error);
            }).finally(
                this.setState({
                    test: ''
                })
            );
        } else {
            this.getProducts(this.state.sort);
        }
    }

    searchProductsHandler = (event) => {
        event.preventDefault();
        this.searchProducts(this.state.sort);
    }

    getCategories = () => {
        axios.get(`https://api.playerbuilds.com/api/category`)
        .then(categories => {
            if(this._isMounted) return;
            this.setState({
                categories: categories.data
            });
        })
        .catch(error => {
            console.log(error);
        });  
    }

    getSubcategories = (event) => {
        var value = event.target.value;
        axios.get(`https://api.playerbuilds.com/api/subcategory?parentID=${value}`)
        .then(subCategories => {
            if(this._isMounted) return
            this.setState({
                selectedCategory: value,
                subCategories: subCategories.data,
            });
        })
        .catch(error => {
            console.log(error);
        });  
    }

    setSubCategory = (event) => {
        if(this._isMounted) return;
        this.setState({
            selectedCategory: event.target.value
        });
    }

    getBookmarks = () => {
        var token = document.cookie.match('(^|;) ?token=([^;]*)(;|$)');
        if(token) {

            let params = new URLSearchParams();
            params.append("session_token", token[2]);

            axios({
                method: 'post',
                url: 'https://api.bolscout.nl/api/bookmark',
                data: params
            })
            .then(bookmarks => {
                if(bookmarks.data.bookmarks.length > 0) {
                    this.setState({
                        bookmarks: bookmarks.data.bookmarks
                    });
                }
            }).catch(error => {
                
            })
        }
    }

    setModal = (modal) => {
        if(!modal) {
            this.setState({
                showModal: false
            });
        }
   
    }

    trackProductModal = (product) => {
        this.setState({
            showModal: true,
            modalData: product
        });
    }

    submitTrack = (event) => {
        event.preventDefault();
        var selectedBookmark = event.target.querySelector("#selectBookmark");
        var bookmarkID = selectedBookmark.options[selectedBookmark.selectedIndex].value;

        if(bookmarkID === "null") {
            alert("Geen bookmark gekozen");
            return false;
        }
        
        this.trackProduct(this.state.modalData, bookmarkID);
    }

    trackProduct = (product, bookmarkID) => {
        var params = new URLSearchParams();
        params.append("bookmarkID", bookmarkID)
        params.append("product", JSON.stringify(product));

        axios({
            method: "post",
            url: "https://api.bolscout.nl/api/tracking",
            data: params
        })
        .then(response => {
            var token = document.cookie.match('(^|;) ?token=([^;]*)(;|$)');
            if(token) {

                var params = new URLSearchParams();
                params.append('session_token', token[2]);
                axios.post("https://api.bolscout.nl/api/tracking/count", params)
                .then(response => {
                    if(response.data.error) {
                        
                    } else {
                        this.setState({
                            tracks: response.data.total
                        });
                    }
                });
            }
            alert(response.data.response);

            if(response.data.response !== "Het product is toegevoegd aan de gekozen bookmark") {
                return false;
            }

            this.setState({
                showModal: false,
                modalData: []
            });
        }).catch(error => {
            console.log(error);
        });
    }

    componentDidUpdate = () => {
        this.updateStateOnScroll();
        this.getCategories();

        document.querySelector("#advancedSearch").onclick = (event) => {
            event.preventDefault();

            let field = document.getElementById("advanced-field-wrapper");
            if(field.style.display === "block") {
                field.style.display = "none";
                this.setState({
                    selectedCategory: "",
                });
            } else {
                field.style.display = "block";
            }
        }
    }

    updateStateOnScroll = () => {
        window.onscroll = () => {
            if((document.documentElement.scrollTop + window.innerHeight) === document.documentElement.offsetHeight) {
                let offsetVal = (this.state.offset === 0) ? this.state.limit : this.state.offset + this.state.limit;
                let productObject = this.state.data;
                if (this.state.query !== "") {
                    axios.get(`https://api.playerbuilds.com/api/product/search?ids=${this.state.selectedCategory}&limit=${this.state.limit}&offset=${offsetVal}&product=${this.state.query}&sort=${this.state.sort}`)
                    .then(products => {
                        if(this._isMounted) return;
                        if(typeof products.data.products !== "undefined") {
                            this.setState({
                                data: productObject.concat(products.data.products),
                                limit: this.state.limit,
                                offset: offsetVal,
                                query: this.state.query,
                                loaded: true,
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
                } else {
                    axios.get(`https://api.playerbuilds.com/api/product?ids=${this.state.selectedCategory}&sort=${this.state.sort}&limit=${this.state.limit}&offset=${offsetVal}`)
                    .then(products => {
                        if(this._isMounted) return;
                        if(typeof products.data.products !== "undefined") {
                            this.setState({
                                data: productObject.concat(products.data.products),
                                limit: this.state.limit,
                                offset: offsetVal,
                                query: this.state.query,
                                loaded: true,
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
                }
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = true;
    }

    render() {
        const showCategories = this.state.categories.map((order, index) => {
            let classy = (index % 2) === 0 ? "float-left" : "float-right" ;

            return (
                <div className={"advanced-field-col " + classy} key={order.id}>
                    <input type="radio" name="subCategory" value={order.id} id={order.name} onChange={this.getSubcategories} />
                    &nbsp;
                    <label htmlFor={order.name}>{order.name}</label>
                </div>
                
            );
        });

        const showSubCategories = this.state.subCategories.map((order, index) => {
            let classy = (index % 2) === 0 ? "float-left" : "float-right" ;

            return (
                <div className={"advanced-field-col " + classy} key={order.id}>
                    <input type="radio" name="category" value={order.id} id={order.name} onChange={this.setSubCategory} />
                    &nbsp;
                    <label htmlFor={order.name}>{order.name}</label>
                </div>
                
            );
        });

        return(
            <div >
                {this.state.showModal && <TrackModal bookmarks={this.state.bookmarks} product={this.state.modalData} submitTrack={this.submitTrack} setModal={this.setModal} />}
                {this.state.modal && <ProductModal close={this.closeInfoToggler} product={this.state.singleProduct} />}
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap pt-3 pb-2 mb-3 border-bottom align-items-center">
                    <h1 className="h2">Producten</h1>
                    <div className="float-right">
                        <p>Tracked products: {this.state.tracks} / {this.state.max}</p>
                    </div>
                </div>
                <div className="card my-4">
                    <div className="card-header">
                        <form onSubmit={this.searchProductsHandler}>
                            <div className="navbar navbar-expand">
                                <div className="collapse navbar-collapse mr-auto flex-direction-sm-column" id="card-navbar">
                                    <div className="form-inline d-block-sm my-2 my-lg-0">
                                        <div className="input-group w-sm-100">
                                            <input type="text" className="form-control" id="query" placeholder="Zoeken..." />
                                            <div className="input-group-append">
                                                <button className="btn btn-dark"><i className="fas fa-search"></i> Zoeken</button>
                                            </div>
                                        </div>  
                                        <Sort valueArray={this.state.order} method={this.setSort} />
                                    </div>
                                    <div className="ml-auto d-block-sm w-sm-100">
                                        <button className="btn btn-dark w-sm-100" id="advancedSearch" >Geavanceerd zoeken <i className="fas fa-caret-down"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="advanced-field-wrapper" id="advanced-field-wrapper">
                                <div className="advanced-field" >
                                    <small>* Als u Geavanceerd zoeken sluit, zoekt u weer op de normale manier.</small>
                                    <div className="advanced-field-columns">
                                        <div className="advanced-field-col">
                                            <h4>Categorieën</h4>
                                            {showCategories} 
                                        </div>
                                        <div className="advanced-field-col">
                                            <h4 className="h4">Subcategorieën</h4>
                                            {showSubCategories}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="card-body">
                        <small>* Klik op het product voor meer info</small>
                        <ProductTable tracks={this.state.tracks} max={this.state.max} products={this.state.data} infoToggler={this.infoToggler} trackProduct={this.trackProductModal} modal={this.showInfo} />
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductDatabase;