import React, { Component } from 'react';
import axios from 'axios';
import Invoices from './Invoices';
import UpgradeModal from './UpgradeModal';

class SubscriptionSettings extends Component {
    constructor() {
        super();

        this.state = {
            amount: 0,
            status: "",
            subscription: "",
            user_stripe: "",
            period: "",
            create: "",
            endDate: "",
            modal: false,
            name: ""
        }
    }

    pageName = "Abonnements instellingen";

    componentWillMount() {
        document.title = "Instellingen - " + this.pageName;

        var token = document.cookie.match('(^|;) ?token=([^;]*)(;|$)');
        if(token) {
            var params = new URLSearchParams();

            params.append("session_token", token[2]);
            params.append("type", "subscription");
            axios({
                method: "post",
                url: "https://api.bolscout.nl/api/user/",
                data: params
            }).then(response => {
                if(response.data.user_data) {
                    this.setState({
                        amount: response.data.user_data.amount,
                        status: response.data.user_data.status,
                        subscription: response.data.user_data.subscription,
                        user_stripe: response.data.user_data.user_stripe,
                        period: response.data.user_data.period,
                        create: response.data.user_data.create
                    }, () => {
                        var date = new Date(this.state.create);
                        let name;
                        if(this.state.period === 'month') {
                            date.setMonth(date.getMonth() + 1);
                            name = "Maandelijks";
                        } else if (this.state.period === 'quarter') {
                            date.setMonth(date.getMonth() + 3);
                            name = "Kwartaalijks";
                        } else if (this.state.period === 'year') {
                            date.setFullYear(date.getFullYear() + 1);
                            name = "Jaarlijks";
                        }
                        this.setState({
                            name: name,
                            endDate: date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear()
                        });
                    });
                }
            });
        }
    }

    cancelSubscription = event => {
        event.preventDefault();

        var token = document.cookie.match('(^|;) ?token=([^;]*)(;|$)');
        if(token) {
            if(window.confirm("Weet u zeker dat u wilt annuleren?\nHet contract eindigt op " + this.state.endDate)) {

                var params = new URLSearchParams();
                params.append("session_token", token[2]);
                params.append("subscription", this.state.subscription);

                axios({
                    url: "https://api.bolscout.nl/api/payment/cancel",
                    method: "post",
                    data: params
                })
                .then(response => {
                    if(response.data.error) {
                        alert(response.data.error);
                    } else {
                        alert(response.data.message);
                    }
                })
                .catch(error => {
                    alert("Er is een probleem met de server, probeer het later opnieuw");
                })
            } else {
                return false;
            }
        }
    }

    subscriptionModal = event => {
        event.preventDefault();

        this.setState({
            modal: true
        })
    }

    trackModal = (setModal) => {
        this.setState({
            modal: setModal,
        });
    }

    render() {
        return (
            <div className="col-md-12 pt-2 pb-4 bg-white">
                {this.state.modal ? <UpgradeModal setModal={this.trackModal} subscription={this.state} /> : ""}
                <h3 className="h3">Abonnements instellingen</h3>
                <h4 className="h4">{this.state.name} <small className="text-muted">€{("" + this.state.amount / 100).replace('.', ',')}</small></h4>
                <button onClick={this.subscriptionModal}>Upgrade</button><button onClick={this.cancelSubscription}>Annuleren</button><br/>
                {this.state.endDate === "" ? "" : <small>Abonnement eindigt op: {this.state.endDate}, na deze datum zal het abonnement door lopen en per maand opzegbaar zijn <br />
                behalve als het abonnement geannuleerd is door u!</small>} <br />
                <small>Als u nu annuleert wordt het abonnement beindigd op: {this.state.endDate}</small>
                <Invoices customer={this.state.user_stripe} />
            </div>
        );
    }
}

export default SubscriptionSettings;