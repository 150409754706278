import React from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.scss';

const logout = (event) => {
    event.preventDefault();
    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
    window.location.replace("/");
}

const Sidebar = () => {
    return(
        <nav className="d-none d-md-block bg-dark sidebar">
            <div className="sidebar-sticky mx-2">
                <a className="navbar-brand" href="https://bolscout.nl" target="_blank" rel="noopener noreferrer" >
                    <img src="https://www.hibarnsley.com/wp-content/uploads/2017/06/dummy-logo.png" alt="Bol tracker logo" className="sidebar-logo" />
                </a>
                <ul className="nav flex-column">
                    {/* <li className="nav-item">
                        <Link to='/' className="nav-link text-light" ><i className="fas fa-home"></i> Dashboard</Link>
                    </li> */}
                    <li className="nav-item">
                        <Link to='/' className="nav-link text-light"><i className="fas fa-search"></i> Tracker</Link>
                    </li>
                    <li className="nav-item">
                        <Link to='/products' className="nav-link text-light"><i className="fas fa-archive"></i> Producten</Link>
                    </li>
                </ul>

                <ul className="nav flex-column bottom-nav">
                    <li className="nav-item">
                        <Link to='/settings' className="nav-link text-light" ><i className="fas fa-user-cog"></i> Instellingen</Link>
                    </li>
                    <li className="nav-item">
                        <a href="/" to='/logout' className="nav-link text-light" onClick={logout}><i className="fas fa-sign-out-alt"></i> Logout</a>
                    </li>
                </ul>
            </div>
        </nav>
    ); 
}

export default Sidebar