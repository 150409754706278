import React, { Component } from 'react';

class TrackModal extends Component {

    componentDidMount = () => {
        document.getElementById("outerModal").addEventListener("click", this.modalFunction);
        document.getElementById("close").addEventListener("click", this.modalFunction);
    }

    componentWillUnmount = () => {
        document.getElementById("outerModal").addEventListener("click", this.modalFunction);
        document.getElementById("close").addEventListener("click", this.modalFunction);
    }

    modalFunction = (event) => {
        event.preventDefault();
        this.props.setModal(false);
    }

    render() {
        const bookmarks = this.props.bookmarks.map((bookmark, index) => {
            return (
                <option key={index} value={bookmark.bookmark_id}>{bookmark.bookmark_name}</option>
            );
        })

        var title = this.props.product.title;
    
        return(
            <div className="trackModal">
                <div id="outerModal" className="outerModal"></div>
                <div className="innerModal bg-light" id="innerModal">
                    <div className="modal-head">
                        <h4 className="modal-title">{title.substr(0, 40)}{title.length > 40 ? "..." : ""}</h4>
                        <a href="/" className="modal-close" id="close"><i className="fas fa-times-circle"></i></a>
                    </div>
                    
                    <form onSubmit={this.props.submitTrack}>
                        <div className="form-group">
                            <select id="selectBookmark" className="form-control form-control">
                                <option value="null">-- Kies een bookmark --</option>
                                {bookmarks}
                            </select>
                        </div>
                        <button className="btn btn-outline-dark form-control">Track product</button>
                    </form>
                </div>
            </div>
        );
    }
}

export default TrackModal;