import React, { Component} from 'react';
import { IbanElement, injectStripe } from 'react-stripe-elements';
import axios from 'axios';

class SubscripeForm extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);

        this.state = {
            errorMessage: "",
            accountCreated: false,
            plans: [],
            user: []
        }
    }

    componentWillMount() {
        // Gets the payment plans from bolscout server through api
        axios.get("https://api.bolscout.nl/api/plan")
        .then(response => {
            this.setState({
                plans: response.data
            });
        });

        var token = document.cookie.match('(^|;) ?token=([^;]*)(;|$)');
        if(token) {

            var params = new URLSearchParams();
            params.append('session_token', token[2]);
            params.append('type', 'account');
            axios.post("https://api.bolscout.nl/api/user/", params)
            .then(response => {
                this.setState({
                    user: response.data.user_data
                });
            });
        }
    }     

    componentDidMount() {
        document.getElementById("subscription-form").addEventListener("submit", this.submit);
    }

    handleChange = ({error}) => {
        // Used to show the iban error message
        if (error) {
          this.setState({errorMessage: error.message});
        }
    }

    buttonNoClick = (event) => {
        event.preventDefault();
    }

    async submit(event) {
        // Prevents the default submit action
        event.preventDefault();        

        // Change button to unclickable.
        document.getElementById("subscription-form").removeEventListener("submit", this.submit);
        document.getElementById("subscription-form").addEventListener("submit", this.buttonNoClick);
        var button = event.target.querySelector("#register-button");
        button.classList.add("disabled");
        button.innerHTML = "Loading...";
        
        var token = document.cookie.match('(^|;) ?token=([^;]*)(;|$)');
        if(token) {
            
            this.props.stripe.createToken({
                currency: 'eur',
                account_holder_name: this.state.user.firstname + " " + this.state.user.lastname,
                account_holder_type: 'individual',
            })
            .then(result => {
                let displayError = document.getElementById('error-message');

                if(result.error) {
                    document.getElementById("subscription-form").removeEventListener("submit", this.buttonNoClick);
                    document.getElementById("subscription-form").addEventListener("submit", this.submit);
                    var button = event.target.querySelector("#register-button");
                    button.classList.remove("disabled");
                    button.innerHTML = "Registreren";
                    displayError.textContent = result.error.message;
                    return false;
                } else {
                    // If there is no error the script wil continue
                    // Variable params, will create the post parameters.
                    let paymentPlans = document.getElementsByName("paymentPlan");
                    let plan = "";
                    for(let paymentPlan of paymentPlans) {
                        if(paymentPlan.checked === true) {
                            plan = this.state.plans[paymentPlan.value].plan_id;
                        }
                    }

                    let params = new URLSearchParams();
                    params.append('session_token', token[2]);
                    params.append('email', this.state.user.email);
                    params.append('firstName', this.state.user.firstname);
                    params.append('lastName', this.state.user.lastname);
                    params.append('stripeToken', result.token.id);
                    params.append('plan', plan)

                    // With axios we post the data to the bolscout api
                    axios({
                        method: "post",
                        url: "https://api.bolscout.nl/api/payment/add",
                        data: params
                    })
                    .then(response => {
                        if(response) {
                            alert('U bent opnieuw geabonneerd!');
                            // After the message it will redirect you to the login screen.
                            window.location.replace("/");
                        }
                    }).catch(error => {
                        // If there is an error there will be a message that you can't create an account.
                        if(error) {
                            alert("Op dit moment kunt u geen account aanmaken, probeer het later opnieuw!");
                        }
                    });
                }
            });
        }
        
    }


    render() {
        const plans = this.state.plans.map((plan, index) => {
            return (
                <div className="form-check mt-4" key={index}>
                    <input type="radio" name="paymentPlan" id={"plan" + index} value={index} defaultChecked={index === 0 ? true : false} /> &nbsp;
                    <label htmlFor={"plan" + index}>{plan.plan_name} (€ {plan.plan_price / 100})</label><br />
                    <p style={{maxWidth: "900px"}}><b>{plan.plan_description}</b></p>
                </div>   
            );
        });

        return(
            <>
                <div className="row">
                    <form id="subscription-form" method="post">
                        <div className="col-md-12">
                            <h3 className="h3">Betaling</h3>
                            <span>Het maandelijkse bedrag wordt per maand afgeschreven van de gegeven rekenening.</span>
                        </div>
                        <div className="col-md-12">
                            <h3 className="h3">Pakketkeuze *</h3>
                        </div>
                        <div className="col-md-12">
                            {plans}
                        </div>
                        <div className="col-md-12">
                            <div className="checkout">
                                <label>Iban *</label>
                                <IbanElement onChange={this.handleChange} supportedCountries={['SEPA']} />
                                <div id="error-message" role="alert">{this.state.errorMessage}</div>
                            </div>
                        </div>
                        <div className="col-md-12 text-justify pb-2 pt-2 mt-3 mb-3 card">
                            <div id="mandate-acceptance">
                                By providing your IBAN and confirming this payment, you are authorizing
                                Bolscout.nl and Stripe, our payment service provider, to send
                                instructions to your bank to debit your account in accordance with those
                                instructions. You are entitled to a refund from your bank under the terms
                                and conditions of your agreement with your bank. A refund must be claimed
                                within 8 weeks starting from the date on which your account was debited.
                            </div>
                        </div>
                            
                        <button type="submit" id="register-button" className="btn btn-primary w-100">Registreren</button>
                    </form>
                </div>
            </>
        );
    }
}

export default injectStripe(SubscripeForm);