import React, { Component } from 'react';
import axios from 'axios';


class PaymentSettings extends Component {
    constructor() {
        super();

        this.state = {
            company: "",
            address: "",
            city: "",
            zipcode: "",
            province: "",
            country: "",
        }
    }

    pageName = "Betaal instellingen";

    componentWillMount() {
        document.title = "Instellingen - " + this.pageName;

        var token = document.cookie.match('(^|;) ?token=([^;]*)(;|$)');
        if(token) {
            var params = new URLSearchParams();
            params.append("session_token", token[2]);
            params.append("type", "address");
            axios({
                method: "post",
                url: "https://api.bolscout.nl/api/user/",
                data: params
            }).then(response => {
                if(response.data) {
                    this.setState({
                        city: response.data.user_data.city,
                        address: response.data.user_data.address,
                        company: response.data.user_data.company,
                        country: response.data.user_data.country,
                        province: response.data.user_data.province,
                        zipcode: response.data.user_data.zipcode,
                        oldData: {
                            city: response.data.user_data.city,
                            address: response.data.user_data.address,
                            company: response.data.user_data.company,
                            country: response.data.user_data.country,
                            province: response.data.user_data.province,
                            zipcode: response.data.user_data.zipcode,
                        }
                    });
                }
            }).catch(error => {
                console.log(error);
                alert("Probleem met de server!");
            })
        }
    }

    dataHandler = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    updateAccountSettings = event => {
        event.preventDefault();

        if(!this.formCheck()) {
            return false;
        }

        if((this.state.company !== this.state.oldData.company) || (this.state.address !== this.state.oldData.address) || (this.state.city !== this.state.oldData.city) || (this.state.zipcode !== this.state.oldData.zipcode) || (this.state.province !== this.state.oldData.province) || (this.state.country !== this.state.oldData.country)) {
            // if((this.state.firstname === document.getElementById("firstname").value) || (this.state.lastname === document.getElementById("lastname").value) || (this.state.email === document.getElementById("email").value) || (this.state.dateofbirth === document.getElementById("dateofbirth").value)) {
                
                var token = document.cookie.match('(^|;) ?token=([^;]*)(;|$)');
                if(token) {
                
                    let params = new URLSearchParams();
                    params.append("session_token", token[2]);
                    params.append("type", "address");
                    params.append("userData", JSON.stringify(this.state));

                    axios({
                        method: "post",
                        url: "https://api.bolscout.nl/api/user/update",
                        data: params
                    })
                    .then(response => {
                        alert(response.data.message);
                        window.location.reload();
                    })
                
                }

            // }
        }
    }

    formCheck = () => {
        const greenBorder = "1px solid green";
        const redBorder = "1px solid red";
        
        var error = 0;

        var company = document.getElementById("company");
        if((this.state.company !== "") && (company.value !== "")) {
            document.getElementById("company").style.border = greenBorder;
        } else {
            document.getElementById("company").style.border = redBorder;

            error++;
        }

        var address = document.getElementById("address");
        if((this.state.address !== "") && (address.value !== "")) {
            address.style.border = greenBorder;
        } else {
            address.style.border = redBorder;

            error++;
        }

        var city = document.getElementById("city");
        if((this.state.city !== "") && (city.value !== "")) {
            city.style.border = greenBorder;
        } else {
            city.style.border = redBorder;

            error++;
        }

        var zipcode = document.getElementById("zipcode");
        if((this.state.zipcode !== "") && (zipcode.value !== "")) {
            zipcode.style.border = greenBorder;
        } else {
            zipcode.style.border = redBorder;

            error++;
        }

        var province = document.getElementById("province");
        if((this.state.province !== "") && (province.value !== "")) {
            province.style.border = greenBorder;
        } else {
            province.style.border = redBorder;

            error++;
        }

        var country = document.getElementById("country");
        if((this.state.country !== "") && (country.value !== "")) {
            country.style.border = greenBorder;
        } else {
            country.style.border = redBorder;

            error++;
        }

        if(error > 0) {
            return false;
        } else {
            return true;
        }
    }

    render() {
        return(
            <div className="col-md-12 pt-2 pb-2 mb-4 bg-white">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="h3">Betaal instellingen</h3>
                        <form onSubmit={this.updateAccountSettings} name="payment">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="company">Bedrijfsnaam</label>
                                        <input type="text" id="company" name="company" className="form-control" aria-describedby="company" onChange={this.dataHandler} placeholder="Bedrijfsnaam" defaultValue={this.state.company} /> 
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="address">Straatnaam + huisnummer</label>
                                        <input type="text" id="address" name="address" className="form-control" aria-describedby="Address" onChange={this.dataHandler} placeholder="Straatnaam + huisnummer" defaultValue={this.state.address} /> 
                                    </div>
                                </div>
                                <div className="col-sm-12 col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="city">Woonplaats</label>
                                        <input type="text" id="city" name="city" className="form-control" aria-describedby="City" onChange={this.dataHandler} placeholder="Woonplaats" defaultValue={this.state.city} />
                                    </div>  
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="zipcode">Postcode</label>
                                        <input type="text" id="zipcode" name="zipcode" className="form-control" aria-describedby="Zipcode" onChange={this.dataHandler} placeholder="Postcode" defaultValue={this.state.zipcode} />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="province">Provincie</label>
                                        <input type="text" id="province" name="province" className="form-control" aria-describedby="Provincie" onChange={this.dataHandler} placeholder="Provincie" defaultValue={this.state.province} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="country">Land</label>
                                        <input type="text" id="country" name="country" className="form-control" aria-describedby="Land" onChange={this.dataHandler} placeholder="Land" defaultValue={this.state.country} />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-lg-6">

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-lg-4">
                                    <button className="btn btn-outline-dark w-100">Opslaan</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                
            </div>
        );
    }
}

export default PaymentSettings;