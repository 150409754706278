import React, { Component } from 'react';
import SettingsNav from './SettingsNav';
import AccountSettings from './AccountSettings';
import PaymentSettings from './PaymentSettings';
import SubscriptionSettings from './SubscriptionSettings';
import PasswordSettings from './PasswordSettings';
import { Route } from 'react-router-dom';

class Settings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: "account",
            userData: {},
            paymentData: {},
            subscriptionData: {}            
        }

    }

    render() {
        return(
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap pt-3 pb-2 mb-3 border-bottom align-items-center">
                    <h1 className="h2">Instellingen</h1>
                </div>
                <div className="row ml-0 mr-0">
                    <SettingsNav selected={this.state.selected} />
                    <Route exact path="/settings" render={() => <AccountSettings />} />
                    <Route path="/settings/password" render={() => <PasswordSettings />} />
                    <Route path="/settings/payment" render={() => <PaymentSettings />} />
                    <Route path="/settings/subscription" render={() => <SubscriptionSettings />} />

                </div>
            </div>
        );
    }
}

export default Settings;