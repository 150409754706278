import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import 'bootstrap/scss/bootstrap.scss';
import './App.scss';
import Sidebar from './components/common/sidebar/Sidebar';
// import Dashboard from './components/dashboard/';
import Settings from './components/settings/';
import NotFound from './components/notFound';
import ProductDatabase from './components/productDatabase';
import ProductTracker from './components/productTracker';
import Login from './components/login';
import Signup from './components/signup'
import axios from 'axios';
import Canceled from './components/canceled';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: [],
      loggedInStatus: 'NOT_LOGGED_IN',
      status: ""
    }; 
  }

  isLoggedin = () => {
    var token = document.cookie.match('(^|;) ?token=([^;]*)(;|$)');
    if(token) {

      let params = new URLSearchParams();
      params.append("session_token", token[2])
      axios({
        method: "post",
        url: "https://api.bolscout.nl/api/login/auth",
        data: params
      })
      .then(response => {
        if(response.data.token_valid) {
          this.setState({
            loggedInStatus: 'LOGGED_IN',
            status: response.data.status
          }, () => {
            // console.log(this.state);
          })
        } else {
          var expiredDate = new Date();
          expiredDate.setTime(expiredDate.getTime());
          var expires = "expires="+ expiredDate.toUTCString();
          document.cookie = "token = ;" + expires
        }
      }) 
    }
  }

  componentWillMount() {
    this.isLoggedin();
  }

  handler = (userData) => {
    this.setState({
      user: userData,
      loggedInStatus: 'LOGGED_IN'
    });
  }

  setStatus = (status) => {
    this.setState({
      status: status
    });
  }

  render() {
    if(this.state.loggedInStatus === 'NOT_LOGGED_IN') {
      return(
        <Router>
          <Route exact path="/" render={() => <Login handler={this.handler} statusHandler={this.setStatus} />}  />
          <Route path="/signup" component={Signup} />
        </Router>
      );
    } else if(this.state.status === "canceled") {
      return(
        <Canceled />
      );
    } else {
      return (
        <Router>
          <div className="container-fluid h-100vh bg-light">
            <div className="row">
              <Sidebar />
              <main className="col-lg-11 col-md-9 ml-auto px-4 main-container">
                <Switch>
                  {/* <Route exact path="/" component={Dashboard} /> */}
                  <Route exact path="/" component={ProductTracker} />
                  <Route path="/products" component={ProductDatabase} />
                  <Route path="/settings" component={Settings} />
                  <Route component={NotFound} />
                </Switch>
              </main> 
            </div>
          </div> 
        </Router>
      );
    }
  }
}

export default App;
