import React from 'react';

const Select = props => {
    const sorting = props.valueArray.map((order, index) => {
        return <option value={order.method} key={index}>{order.name}</option>;
    });

    return(
        <div className="ml-lg-3 ml-md-0 mt-sm-2 mt-xl-0 form-group w-sm-100">
            <select className="form-control w-sm-100" onChange={props.method}>
                {sorting}
            </select>
        </div>   
    );
}

export default Select;