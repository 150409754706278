import React from 'react';

const BookmarkNav = props => {
    var bookmarkPill = props.bookmarks.map((bookmark, index) => {
        if(index < 10) {
            return(
                <li className={(index === 0 ? "active" : "") + "nav-item display-flex bookmark-navs"} key={index}>
                    <a href="_blank" id={"bookmark-" + bookmark.bookmark_id} className="nav-link float-left" onClick={props.load}>{bookmark.bookmark_name}</a>
                    <a href="_blank" style={{marginLeft: "-8px"}} className="nav-link float-right" onClick={props.remove}><i id={bookmark.bookmark_id} className="fas fa-times"></i></a>
                </li>
            );
        } else {
            return "";
        }
    });

    return(
        <div className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="collapse navbar-collapse">
                <ul className="navbar-nav mr-auto">
                    {bookmarkPill}
                </ul>
                <div className="ml-auto d-block-sm w-sm-100">
                    <button className="btn btn-dark w-sm-100" id="addBookmark" onClick={props.add} ><i className="fas fa-plus"></i></button>
                </div>
            </div>
        </div>
    );
}

export default BookmarkNav;