import React from 'react';

const NotFound = () => {
    return(
        <div className="d-flex h-100vh">
            <div className="row justify-content-center align-self-center w-100">
                <h1 className="h1 w-100 text-center">Error 404, pagina niet gevonden!</h1>
                <br />
                <h2 className="h2 w-100 text-center">De pagina die u zocht bestaat niet!</h2>
            </div>
        </div>
    );
}

export default NotFound;