import React, { Component } from 'react';
import axios from 'axios';

class AccountSettings extends Component {
    constructor() {
        super();

        this.state = {
            firstname: "",
            lastname: "",
            email: "",
            dateofbirth: ""
        }
    }

    pageName = "Persoonlijke instellingen";

    componentWillMount() {
        document.title = "Instellingen - " + this.pageName;

        var token = document.cookie.match('(^|;) ?token=([^;]*)(;|$)');
        if(token) {
            var params = new URLSearchParams();
            params.append("session_token", token[2]);
            params.append("type", "account");
            axios({
                method: "post",
                url: "https://api.bolscout.nl/api/user/",
                data: params
            }).then(response => {
                if(response.data) {
                    var dateofbirth = this.convertDateofbirth(response.data.user_data.dateofbirth);

                    this.setState({
                        firstname: response.data.user_data.firstname,
                        lastname: response.data.user_data.lastname,
                        email: response.data.user_data.email,
                        dateofbirth: dateofbirth,
                        oldData: {
                            firstname: response.data.user_data.firstname,
                            lastname: response.data.user_data.lastname,
                            email: response.data.user_data.email,
                            dateofbirth: dateofbirth,
                        }
                    });
                }
            }).catch(error => {
                alert("Probleem met de server!");
            })
        }
    }

    convertDateofbirth = (timestamp) => {
        if (timestamp > 0) { 
            var date = new Date(timestamp * 1000);
            var year = date.getFullYear();
            var day = ("0" + date.getDate()).slice(-2);
            var month = ("0" + (date.getMonth() + 1)).slice(-2);
            return (year + "-" + month + "-" + day).toString();
        }
    }

    updateAccountSettings = event => {
        event.preventDefault();

        if(!this.formCheck()) {
            return false;
        }

        if((this.state.firstname !== this.state.oldData.firstname) || (this.state.lastname !== this.state.oldData.lastname) || (this.state.email !== this.state.oldData.email) || (this.state.dateofbirth !== this.state.oldData.dateofbirth)) {
            if((this.state.firstname === document.getElementById("firstname").value) || (this.state.lastname === document.getElementById("lastname").value) || (this.state.email === document.getElementById("email").value) || (this.state.dateofbirth === document.getElementById("dateofbirth").value)) {
                
                var token = document.cookie.match('(^|;) ?token=([^;]*)(;|$)');
                if(token) {
                
                    let params = new URLSearchParams();
                    params.append("session_token", token[2]);
                    params.append("type", "account");
                    params.append("userData", JSON.stringify(this.state));

                    axios({
                        method: "post",
                        url: "https://api.bolscout.nl/api/user/update",
                        data: params
                    })
                    .then(response => {
                        alert(response.data.message);
                        window.location.reload();
                    })
                
                }

            }
        }
    }

    formCheck = () => {
        const greenBorder = "1px solid green";
        const redBorder = "1px solid red";
        
        var error = 0;

        var firstname = document.getElementById("firstname");
        if((this.state.firstname !== "") && (firstname.value !== "")) {
            document.getElementById("firstname").style.border = greenBorder;
        } else {
            document.getElementById("firstname").style.border = redBorder;

            error++;
        }

        var lastname = document.getElementById("lastname");
        if((this.state.lastname !== "") && (lastname.value !== "")) {
            lastname.style.border = greenBorder;
        } else {
            lastname.style.border = redBorder;

            error++;
        }

        var email = document.getElementById("email");
        if((this.state.email !== "") && (email.value !== "")) {
            email.style.border = greenBorder;
        } else {
            email.style.border = redBorder;

            error++;
        }

        var dateofbirth = document.getElementById("dateofbirth");
        if(dateofbirth.value !== "") {
            
            let today = new Date();
            let birthDate = new Date(dateofbirth.value);
            let age = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }    

            if (age < 18) {
                dateofbirth.style.border = redBorder;

                error++;
            } else {
                dateofbirth.style.border = greenBorder;
            }
        } else {
            dateofbirth.style.border = redBorder;
            
            error++
        }

        if(error > 0) {
            return false;
        } else {
            return true;
        }
    }

    dataHandler = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    render() {
        return(
            <div className="col-md-12 pt-2 pb-4 bg-white">
                <h3 className="h3">Persoonlijke instellingen</h3>
                    <form onSubmit={this.updateAccountSettings} id="settings-form">
                        <div className="row">
                            <div className="col-sm-12 col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="firstname">Voornaam</label>
                                    <input type="text" className="form-control" name="firstname" id="firstname" aria-describedby="Voornaam" onChange={this.dataHandler} placeholder="Voornaam" defaultValue={this.state.firstname} />
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="lastname">Achternaam</label>
                                    <input type="text" className="form-control" name="lastname" id="lastname" aria-describedby="Achternaam" onChange={this.dataHandler} placeholder="Achternaam" defaultValue={this.state.lastname} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="email">Email address</label>
                                    <input type="email" className="form-control" name="email" id="email" aria-describedby="Email" onChange={this.dataHandler} placeholder="Email" defaultValue={this.state.email} />
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="dateofbirth">Geboortedatum</label>
                                    <input type="date" className="form-control" name="dateofbirth" id="dateofbirth" aria-describedby="Geboortedatum" onChange={this.dataHandler} placeholder="Geboortedatum" defaultValue={this.state.dateofbirth} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-lg-4 offset-sm-0">
                                <button className="btn btn-outline-dark w-100">Opslaan</button>
                            </div>
                        </div>
                </form>           
            </div>
        );
    }
}

export default AccountSettings;