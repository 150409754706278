import React from 'react';
import { Link } from 'react-router-dom';

const removeActive = (parentName) => {
    var classes = document.getElementById(parentName).getElementsByClassName("active");
    
    for(var i = 0; i < classes.length; i++) {
        classes[i].classList.remove("active");
    }
}

const componentDidMount = () => {
    var uri = window.location.pathname.split("/");
    
    setTimeout(() => {
        removeActive("settingsNav");
        if(uri[2] !== undefined) {
            document.getElementById(uri[2]).className = "nav-link active";
        } else {
            document.getElementById(uri[1]).className = "nav-link active";
        }
    }, 0);
}

const SettingsNav = (props) => {
    componentDidMount();

    return(
        <nav className="navbar navbar-expand navbar-dark bg-dark col-md-12" id="settingsNav">
            <div className="collapse navbar-collapse">
            <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                    <Link to="/settings" className="nav-link" id="settings">Account</Link>
                </li>
                <li className="nav-item">
                    <Link to="/settings/password" className="nav-link" id="password">Wachtwoord</Link>
                </li>
                <li className="nav-item">
                    <Link to="/settings/payment" className="nav-link" id="payment">Betalingen</Link>
                </li>
                <li className="nav-item">
                    <Link to="/settings/subscription" className="nav-link" id="subscription">Abonnement</Link>
                </li>   
            </ul>
            </div>
        </nav>
    );
}

export default SettingsNav;