import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import axios from 'axios';

class TrackModal extends Component {
    constructor() {
        super();

        this.state = {
            chart: {},
            chartCat: 'sales',
            data: [],
            message: ""
        }
    }

    componentWillMount = () => {
        let product = this.props.product.track_product_id;
        axios({
            method: "get",
            url: `https://api.bolscout.nl/api/tracklog?productID=${product}&scope=week`
        })
        .then((response) => {
            if(response.data.error) {
                this.setState({
                    message: "Voor dit product is nog geen data beschikbaar, data wordt elke dag rond 2 uur aangepast."
                });
            } else {
                const sellerArray = Object.keys(response.data.response).map(i => response.data.response[i])

                this.setState({
                    data: sellerArray
                }, this.setChart);
            }
        });
    }

    setChart = () => {
        const data = this.state.data;

        let chartArray = [];
        let dateArray = [];
        for(const seller in data) {
            let stockArray = [];
            
            for(const dates in data[seller]["graph_data"]) {
                if(!dateArray.includes(data[seller]["graph_data"][dates]["date"])) {
                    dateArray.push(data[seller]["graph_data"][dates]["date"]);
                }
            }

            for(const dates in data[seller]["graph_data"]) {
                stockArray.push(data[seller]["graph_data"][dates][this.state.chartCat]);
            }

            var R = parseInt(Math.random() * 255);
            var G = parseInt(Math.random() * 255);
            var B = parseInt(Math.random() * 255);

            chartArray.push({
                label: data[seller]["seller_name"],
                data: stockArray,
                backgroundColor: "rgba(0, 0, 0, 0.0)",
                borderColor: `rgba(${R}, ${G}, ${B}, 1)`,
                borderWidth: 1.5,
                lineTension: 0.1
            });
        }

        // let date = new Date();

        // for(let i = 0; i < 32; i++) {
        //     date.setDate(date.getDate() - 1);
        //     dateArray.push(date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear());          
        // }

        this.setState({
            chart: {
                labels: dateArray,
                datasets: chartArray
            }
        });
    }

    componentDidMount = () => {
        document.getElementById("outerModal").addEventListener("click", this.modalFunction);
        document.getElementById("close").addEventListener("click", this.modalFunction);
    }

    componentWillUnmount = () => {
        document.getElementById("outerModal").addEventListener("click", this.modalFunction);
        document.getElementById("close").addEventListener("click", this.modalFunction);
    }

    modalFunction = (event) => {
        event.preventDefault();

        this.props.setModal(false);
    }

    setMode = (event) => {
        let targ = event.target;
        let choice = targ[targ.selectedIndex].value;

        this.setState({
            chartCat: choice
        }, () => {
            this.setChart();
        });
    }

    render() {
        let title = this.props.product.track_title;

        return(
            <div className="trackModal">
                <div id="outerModal" className="outerModal"></div>
                <div className="innerModal-big bg-light" id="innerModal">
                    <div className="modal-head">
                        <h4 className="modal-title">{title.substr(0, 40)}{title.length > 40 ? "..." : ""}</h4>
                        <a href="/" className="modal-close" id="close"><i className="fas fa-times-circle"></i></a>
                    </div>
                    <div>
                        <div>
                            <select onChange={this.setMode}>
                                <option value="sales">Verkopen</option>
                                <option value="revenue">Omzet</option>
                                <option value="stock">Voorraad</option>
                            </select>
                        </div>
                        {this.state.message === "" ? "" : <h4>{this.state.message}</h4>}
                        <div className="">
                            <Line 
                                data={this.state.chart} 
                                width={60}
                                height={15}
                                />
                        </div>
                    </div>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Verkoper</th>
                                <th>Prijs</th>
                                <th>Sales / maand</th>
                                <th>Omzet / maand</th>
                                <th>Voorraad</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.map((seller, index) => {
                                let price = 0, sales = 0, revenue = 0, stock = 0;
                                for(let data in seller["graph_data"]) {
                                    price = (parseInt(seller["graph_data"][data]["price"]) / 100).toFixed(2).toString().replace('.', ',');
                                    sales += parseInt(seller["graph_data"][data]["sales"])
                                    revenue = (parseInt(seller["graph_data"][data]["revenue"]) / 100).toFixed(2).toString().replace('.', ',');
                                    stock = seller["graph_data"][data]["stock"];
                                }

                                return(
                                    <tr key={index}>
                                        <td>{seller.seller_name}</td>
                                        <td>€{price}</td>
                                        <td>{sales}</td>
                                        <td>€{revenue}</td>
                                        <td>{stock}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default TrackModal;