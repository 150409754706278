import React, {Component} from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import SubscripeForm from './SubscripteForm';

class Canceled extends Component {
    constructor() {
        super();

        this.state = {
            errorMessage: "",
            accountCreated: false,
            plans: []
        }
    }

    logout = (event) => {
        event.preventDefault();
        document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
        window.location.replace("/");
    }

    render() {
        return(
            <div className="container-fluid h-100vh bg-light">
                <div className="row">
                <nav className="d-none d-md-block bg-dark sidebar">
                    <div className="sidebar-sticky mx-2">
                        <a className="navbar-brand" href="https://bolscout.nl" target="_blank" rel="noopener noreferrer" >
                            <img src="https://www.hibarnsley.com/wp-content/uploads/2017/06/dummy-logo.png" alt="Bol tracker logo" className="sidebar-logo" />
                        </a>
                        <ul className="nav flex-column">
                            
                        </ul>

                        <ul className="nav flex-column bottom-nav">
                            <li className="nav-item">
                                <a href="/" to='/logout' className="nav-link text-light" onClick={this.logout}><i className="fas fa-sign-out-alt"></i> Logout</a>
                            </li>
                        </ul>
                    </div>
                </nav>
                    <main className="col-lg-11 col-md-9 ml-auto px-4 main-container">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap pt-3 pb-2 mb-3 border-bottom align-items-center">
                            <h1 className="h2">Abonnement</h1>
                        </div>  
                        <div>
                            <p>U heeft geen actief abonnement meer, om verder te gaan met het gebruikmaken van het platform moet u abonneren</p>
                            {/* <StripeProvider apiKey="pk_live_NBN4aNxtQBrHhuF9XwxM9MGC00ekMwENht"> */}
                            <StripeProvider apiKey="pk_live_NBN4aNxtQBrHhuF9XwxM9MGC00ekMwENht">
                                <Elements>
                                    <SubscripeForm />
                                </Elements>
                            </StripeProvider>
                        </div>
                    </main> 
                </div>
            </div> 
        );
    }
}

export default Canceled;