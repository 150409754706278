import React, { Component } from 'react';
import axios from 'axios';
import 'bootstrap/scss/bootstrap.scss';
import './Login.scss';
import { Link } from 'react-router-dom';

class Login extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            username: '',
            password: '',
            response: '',
            logged: false,
            userData: [],
            message: ''
        }
    }

    formHandler = input => {
        this.setState({
            [input.target.id]: input.target.value
        });
    }

    loginHandler = (event) => {
        event.preventDefault();

        const params = new URLSearchParams();
        params.append('username', this.state.username);
        params.append('password', this.state.password);
        axios({
            method: 'post',
            url: 'https://api.bolscout.nl/api/login/',
            data: params
        })
        .then((response) => {
            if((response.data.logged_in === true) && (response.data.user)) {
                this.props.statusHandler(response.data.status);
                var d = new Date();
                d.setTime(d.getTime() + (30*24*60*60*1000));
                var expires = "expires="+ d.toUTCString();
                document.cookie = "token=" + response.data.user.token + "; " + expires;
                this.props.handler(response.data.userData, response.data.logged);
            } else {
                this.setState({message: response.data.message});
            }
        })
        .catch((error) => {
            this.setState({message: 'Er is een probleem met inloggen.'});
        });
    }

    message = () => {
        return(
            <div className="alert alert-danger login-message" role="alert">
                {this.state.message}
            </div>
        );
    }

    render() {
        return(
            <div className="container-fluid bg-dark">
                <div className="row h-100vh flex-column flex-login">
                    {(this.state.message !== '') ? this.message() : ''}
                    <div className="login-block bg-light">
                        <a href="https://bolscout.nl">
                            <figure className="image">
                                <img src="https://www.hibarnsley.com/wp-content/uploads/2017/06/dummy-logo.png" alt="Bol tracker logo" className="sidebar-logo" />
                            </figure>
                        </a>
                        <form onSubmit={this.loginHandler} className="mb-2">
                            <div className="form-group">
                                <div className="input-group">    
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <i className="fa fa-user"></i>
                                        </div>
                                    </div>
                                    <input type="text" className="form-control" id="username" aria-describedby="Username" placeholder="Gebruikersnaam" onChange={this.formHandler} />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <i className="fa fa-key"></i>
                                        </div>
                                    </div>
                                    <input type="password" className="form-control" id="password" aria-describedby="password" placeholder="wachtwoord" onChange={this.formHandler} />
                                </div>
                                <a href="_blank"><small>Wachtwoord vergeten? <u>klik hier</u></small></a>
                            </div>
                            <button type="submit" className="btn btn-primary w-100">Login</button>
                        </form>
                        <Link to="/signup" className="btn btn-secondary w-100">Registreren</Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;